import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'feeStructure',
    'additionalPaymentEditBtn',
    'createAdditionalPaymentModal',
    'modifyAdditionalPaymentId',
    'modifyAdditionalPaymentFormEmployee',
    'modifyAdditionalPaymentFormAmount',
    'modifyAdditionalPaymentFormReason',
    'modifyAdditionalPaymentFormPayableYes',
    'modifyAdditionalPaymentFormPayableNo',
    'overrideEmployee',
    'overrideAdditions',
    'overrideAdditionsYes',
    'overrideAdditionsNo',
    'overridePenalties',
    'overridePenaltiesYes',
    'overridePenaltiesNo',
    'paymentSpinner',
    'paymentFee',
    'includeTaxes'
  ]

  connect() {
    console.log("connected to Payments");
  }

  // [WORK IN PROGRESS]
  formChanged(){
    var dispute_resolution_submit_btm = $('#dispute-resolution-submit-btm')[0];
    var formatting_penalty_checkbox = $('#formatting-checkbox')[0];
    var plagiarism_penalty_checkbox = $('#plagiarism-checkbox')[0];
    var delivery_penalty_checkbox = $('#delivery-checkbox')[0];
    var deny_yes = $('#denyYes')[0];
    var deny_no = $('#denyNo')[0];

    //if deny all then all must be unchecked
    if (deny_yes.checked == true) {
      formatting_penalty_checkbox.checked = false;
      plagiarism_penalty_checkbox.checked = false;
      delivery_penalty_checkbox.checked = false;
    }


    // only if the firm has something filled that the submit button becomes available
    if (formatting_penalty_checkbox.checked || plagiarism_penalty_checkbox.checked || delivery_penalty_checkbox.checked || deny_yes.checked) {
      dispute_resolution_submit_btm.disabled = false;
    } else {
      dispute_resolution_submit_btm.disabled = true;
    }

  }

  // [WORK IN PROGRESS]
  modify(){
    var order_id = this.targets.find("paymentDetails").dataset.orderid;
    var formattingPenalties = this.targets.find("paymentDetails").dataset.formattingPenalties;
    var plagiarismPenalties = this.targets.find("paymentDetails").dataset.plagiarismPenalties;
    var plagiarismCost = this.targets.find("paymentDetails").dataset.plagiarismCost;
    var deliveryPenalties = this.targets.find("paymentDetails").dataset.deliveryPenalties;

    console.log(`for order ${order_id}`);

    // display penalties after remove the currency
    if (( formattingPenalties.replace(/[^0-9.-]+/g,"")) > 0 ) {
      $('#formatting-penalty-value').text(formattingPenalties);
      $('#formatting-field').show();
    } else {
      $('#formatting-penalty-value').text("");
      $('#formatting-field').hide();
    }
    // console.log(`formatting penalties ${formattingPenalties}`);

    if (( plagiarismPenalties.replace(/[^0-9.-]+/g,"")) > 0 ) {
      $('#plagiarism-penalty-value').text(plagiarismPenalties);
      $('#plagiarism-field').show();
    } else {
      $('#plagiarism-penalty-value').text("");
      $('#plagiarism-field').hide();
    }
    // console.log(`plagiarism penalties ${plagiarismPenalties}`);

    if (( deliveryPenalties.replace(/[^0-9.-]+/g,"")) > 0 ) {
      $('#delivery-penalty-value').text(deliveryPenalties);
      $('#delivery-field').show();
    } else {
      $('#delivery-penalty-value').text("");
      $('#delivery-field').hide();
    }
    // console.log(`delivery penalties ${deliveryPenalties}`);

    if (( plagiarismCost.replace(/[^0-9.-]+/g,"")) > 0 ) {
      $('#plagiarism-cost-value').text(plagiarismCost);
      $('#plagiarism-cost-field').show();
    } else {
      $('#plagiarism-cost-value').text("");
      $('#plagiarism-cost-field').hide();
    }
    console.log(`plagiarism-cost penalties ${plagiarismCost}`);

    // display the form
    $('#dispute-order-id')[0].value = order_id;
    $('#request-model-payment-modification-modal').modal('toggle');
  }

  // [WORK IN PROGRESS]
  showDisputedPenalties(dispute_details) {
    if ( dispute_details.includes('formatting') ) {
      $('#formatting-field').show();
    }
    if ( dispute_details.includes('plagiarism') ) {
      $('#plagiarism-field').show();
    }
    if ( dispute_details.includes('delivery') ) {
      $('#delivery-field').show();
    }
  }

  // [WORK IN PROGRESS]
  paymentDisputeResolution() {
    var dispute_id = this.targets.find("disputeDetails").dataset.dispute_id;
    var dispute_reason = this.targets.find("disputeDetails").dataset.dispute_reason;
    var dispute_details = this.targets.find("disputeDetails").dataset.dispute_details;

    this.showDisputedPenalties(dispute_details);
    $('#dispute-id')[0].value = dispute_id;
    $('#model-dispute-reason')[0].textContent = dispute_reason;
    $('#payment-dispute-resolve-modal').modal('toggle');
  }

  // Calculates the payment fees for the total payout amount
  //
  // @param feeJson [JSON] generated by the employee model
  //     feel_type [String] type of the fee, percent or fixed
  //     fee_rate [String] number value of the fee
  // @param payout_total [Float] payout total amount of all
  //     the orders
  //
  // @return [Float] value of the fees
  getFeeAmount(feeJson, payout_total){
    var feeType = feeJson["fee_type"];
    var rate = feeJson["fee_rate"]["rate"];
    var cap = feeJson["fee_rate"]["cap"];

    if (feeType == "percent") {
      var new_total = payout_total - (payout_total * rate);
      var new_fee = new_total * rate;
      var fees = new_fee
      if (fees > cap ) {
        return this.roundNum(20, 2)
      } else {
        return this.roundNum(fees, 2);
      }
    } else {
      return rate;
    }
  }

  getInternalFeeAmount(feeJson, payout_total){
    var feeType = feeJson["fee_type"];
    var rate = feeJson["fee_rate"]["rate"];
    var cap = feeJson["fee_rate"]["cap"];

    if (feeType == "percent") {
      var fees = payout_total * rate
      if (fees > cap ) {
        return this.roundNum(20, 2)
      } else {
        return this.roundNum(fees, 2);
      }
    } else {
      return rate;
    }
  }

  // Retrieves tax information for a specific payment amount and employee ID.
  //
  // @param [number] amount - The payment amount for which taxes are to be calculated.
  // @param [{]number] employeeId - The ID of the employee associated with the payment.
  // @return [{]Object|null] The tax data if the request is successful; otherwise, null.
  getTaxes(amount, employeeId) {
    let taxes = null;

    setupAjax();

    $.ajax({
      url: "/payments/taxes",
      method: "GET",
      async: false,
      data: {
        payment: {
          amount: amount,
          employee_id: employeeId
        }
      },
      success: function(data) {
        taxes = data;
      },
      error: function(xhr, status, error) {
        console.error("Error fetching taxes:", error);
      }
    });

    return taxes;
  }

  parseAmount(amount) {
    return parseFloat(amount.replace("$",""));
  }

  createOrderJson(element) {
    return {
      order_id: element.dataset.order_id,
      order_job: element.dataset.order_job
    };
  }

  createAdditionalPaymentJson(element) {
    return {
      additional_payment_id: element.dataset.additional_payment_id
    };
  }

  calculatedPayout() {
    var feeStructure = this.targets.find("paymentFee").dataset.feestructure;
    var includeTaxes = this.targets.find("paymentFee").dataset.includetaxes;
    var payoutTotal = 0.00;
    var $payoutsSum = $('#payouts-sum')[0];
    var $totalPayWithTaxes = $('#payouts-with-taxes')[0];
    var $total = $('#payments-total')[0];
    var employeeId = $total.dataset.employee;
    var $payoutController = $('.paypal-controller');
    var $payoutFee = $('#payments-fees')[0];
    var $payoutInternalFee = $('#payments-internal-fees')[0];
    var $gstHstTax = $('#gst-hst-taxes')[0];
    var $qstTax = $('#qst-taxes')[0];
    var $pstTax = $('#pst-taxes')[0];
    var payouts = [];
    var selectedReports = $('.order-payment:checkbox:checked');
    var fee = 0.00;
    var taxes;

    for (let index = 0; index < selectedReports.length; index++) {
      const element = selectedReports[index];
      if (element.id == "primary-payment") {
        payouts.push(this.createOrderJson(element));
        payoutTotal += this.parseAmount(element.dataset.amount);
      } else if (element.id == "additional-payment") {
        payouts.push(this.createAdditionalPaymentJson(element));
        payoutTotal += this.parseAmount(element.dataset.amount);
      }

      payoutTotal = this.roundNum(payoutTotal,2);
    }

    var netPayout = payoutTotal
    netPayout = this.roundNum(netPayout,2);

    $payoutsSum.innerHTML = this.asCurrency(payoutTotal);

    var totalTaxes = 0.00;
    if (includeTaxes == 'true') {
      taxes = this.getTaxes(netPayout, employeeId);
      if (taxes.HST !== undefined) {
          $gstHstTax.innerHTML = this.asCurrency(taxes.HST);
          totalTaxes += taxes.HST
      }
      if (taxes.QST !== undefined) {
          $qstTax.innerHTML = this.asCurrency(taxes.QST);
          totalTaxes += taxes.QST;
      }
      if (taxes.PST !== undefined) {
          $pstTax.innerHTML = this.asCurrency(taxes.PST);
          totalTaxes += taxes.PST;
      }

      totalTaxes = this.roundNum(totalTaxes,2);
    }

    var subTotal = this.roundNum(payoutTotal+totalTaxes,2);
    if (feeStructure !== '{}' && feeStructure !== '') {
      var json = JSON.parse(feeStructure)
      var paypalFee = this.getFeeAmount(JSON.parse(feeStructure), subTotal);
      fee = this.getInternalFeeAmount(JSON.parse(feeStructure), subTotal);
      var internalFee = this.roundNum(fee - paypalFee, 2)

      if (json.fee_type == 'percent') {
        if (fee > json.fee_rate.cap) {
          fee = 20.00;
        }
        payoutTotal = payoutTotal+totalTaxes;
      }

      if ($payoutFee !== undefined) {
        $payoutFee.innerHTML = `-${this.asCurrency(paypalFee)}`;
      }

      if ($payoutInternalFee !== undefined) {
        $payoutInternalFee.innerHTML = `-${this.asCurrency(internalFee)}`;
      }
    }

    payoutTotal = this.roundNum(payoutTotal,2);
    payoutTotal = subTotal - fee;

    $totalPayWithTaxes.innerHTML = `${this.asCurrency(netPayout+totalTaxes)}`
    $total.innerHTML = `${this.asCurrency(payoutTotal)}`;
    $total.dataset.payouts = JSON.stringify(payouts);

    if (payoutTotal > 0 ){
      $payoutController.show();
    } else {
      $payoutController.hide();
    }
  }

  asCurrency(num) {
    return "$"+this.roundNum(num, 2).toFixed(2);
  }

  // Displays a modal to warn of a missing payment email so manager or employee
  //    are able to fill it out. payment submissions wont work without
  //    paypal email
  //
  // Toggles the modal on and sets the link to the employee profile
  missingPaypalEmail() {
    var employeeProfile = this.targets.find("paymentDetails").dataset.employeeProfile;
    var employeeProfileLink = $('#missing-paypal-employee-profile')[0];
    employeeProfileLink.href = employeeProfile;
    $('#missingPaypalEmailModal').modal('toggle');
  }

  // Toggle spinner off
  // update modal with success
  notifyPayoutSuccess(){
    $('#modal-title')[0].innerHTML = "Payment Processed";
    $('#payment-spinner').remove();
    $('#payment-success').toggle('is-visible');
    $('#payment-success-btn').toggle('is-visible');
  }

  // Toggle spinner off
  // update modal with error messages
  notifyPayoutFail(data){
    $('#modal-title')[0].innerHTML = "Payment Collection Failed!";
    $('#paypal-error-name')[0].innerHTML = data.error_name;
    $('#paypal-error-message')[0].innerHTML = data.error_message;
    $('#paypal-error-bug-id')[0].innerHTML = data.error_debug_id;
    $('#payment-spinner').remove();
    $('#payment-fail').toggle('is-visible');
    $('#payment-fail-btn').toggle('is-visible');
  }

  beginProcessingPayment(){
    $('#order-payment-processing-modal').modal('toggle');
    $('#payment-spinner').toggle('is-visible');
  }

  // Marks orders and additional payments as requested for payout
  request_payout(event) {
    if (!confirm('Are you sure?')) {
      event.preventDefault();
      return;
    }

    var $collectPaymentBtn = $('#payment-collect-btn');
    var $total = $('#payments-total')[0];
    var employeeId = $total.dataset.employee;
    var requested_payouts = $total.dataset.payouts;
    var paymentDetailfilled = event.currentTarget.dataset.employeepaymentdetails;

    if (paymentDetailfilled === 'false') {
      $('#missingPaypalEmailModal').modal('toggle');
    } else {
      $collectPaymentBtn.remove();

      var that = this;

      setupAjax();
      $.ajax({
        url: "/payments/request_payout",
        method: "POST",
        data: {
          payment: {
            requested_payouts: requested_payouts,
            employee_id: employeeId
          }
        },
        dataType: 'json',
        success: function(data) {
          window.location.reload();
        },
        error: function(data) {
          window.location.reload();
        }
      });
    }
  }

  collect(event) {
    if (!confirm('Are you sure?')) {
      event.preventDefault();
      return;
    }

    var $collectPaymentBtn = $('#payment-collect-btn');
    var $total = $('#payments-total')[0];
    var employeeId = $total.dataset.employee;
    var readyPayouts = $total.dataset.payouts;

    $collectPaymentBtn.remove();

    var that = this;

    $.ajax({
      url: "/payments/ready_payouts",
      method: "POST",
      data: {
        payment: {
          ready_payouts: readyPayouts,
          employee_id: employeeId
        }
      },
    });
  }

  // Marks orders and additional payments as paid
  mark_paid(event) {
    if (!confirm('Are you sure?')) {
      event.preventDefault();
      return;
    }

    var $collectPaymentBtn = $('#payment-collect-btn');
    var $total = $('#payments-total')[0];
    var employeeId = $total.dataset.employee;
    var paidPayouts = $total.dataset.payouts;

    $collectPaymentBtn.remove();

    var that = this;

    $.ajax({
      url: "/payments/mark_paid",
      method: "POST",
      data: {
        payment: {
          paid_payouts: paidPayouts,
          employee_id: employeeId
        }
      },
    });
  }

  // Displays the payment override modal with the correct employee preselected
  //
  // When override button is clicked on a specific employee in the payment panel in an order. This
  //  function pre-selects the employee that this override is meant for. once index of the employee
  //  is selected. It toggles to display the modal
  overridePayment(event){
    var employeeId = event.currentTarget.dataset.employeeId
    var employee = this.overrideEmployeeTarget
    var selectedEmployeeOption = employee.querySelectorAll(`option[value='${employeeId}']`);
    selectedEmployeeOption[0].selected = true;
    $('#paymentOverrideModal').modal('toggle');

    let employee_position = event.currentTarget.dataset.employeePosition;

    if (employee_position == "writer"){
      this.overrideAdditionsYesTarget.checked = false
      this.overrideAdditionsNoTarget.checked = false
      this.overrideAdditionsTarget.hidden = true;
    } else if (employee_position == 'qa_editor' || employee_position == 'qc_editor') {
      this.overrideAdditionsYesTarget.checked = false
      this.overrideAdditionsNoTarget.checked = false
      this.overrideAdditionsTarget.hidden = true;
      this.overridePenaltiesYesTarget.checked = false
      this.overridePenaltiesNoTarget.checked = false
      this.overridePenaltiesTarget.hidden = true;
    } else {
      this.overrideAdditionsYesTarget.checked = true
      this.overrideAdditionsNoTarget.checked = false
      this.overrideAdditionsTarget.hidden = false;
    }

  }

  // Auto checks and unchecked all order checkboxes for easy select all User interface
  selectAllPayouts() {
    var selectAllPayouts = $('.select-all-payouts')[0]

    if (selectAllPayouts.checked) {
      $('.order-payment').prop("checked", true);
    } else {
      $('.order-payment').prop("checked", false);
    }

    this.calculatedPayout()
  }

  // fills the additional payment for and displays the modal
  //
  // @param event [DOM event] to identify which additional payment is requesting to edit
  //
  // @return change html and display the modal
  editAdditionalPayment(event){
    var details = event.currentTarget.dataset.details;
    details = details.replaceAll('%20',' ');
    var additionalPaymentJson = JSON.parse(details);
    var additionalPaymentModal = $('#modifyAdditionalPaymentModal');

    this.modifyAdditionalPaymentIdTarget.value = additionalPaymentJson.id;
    this.modifyAdditionalPaymentFormEmployeeTarget.value = additionalPaymentJson.employee_id;
    this.modifyAdditionalPaymentFormAmountTarget.value = additionalPaymentJson.amount;
    this.modifyAdditionalPaymentFormReasonTarget.value = additionalPaymentJson.reason;

    if (additionalPaymentJson.payable){
      this.modifyAdditionalPaymentFormPayableYesTarget.checked = true
      this.modifyAdditionalPaymentFormPayableNoTarget.checked = false
    } else {
      this.modifyAdditionalPaymentFormPayableNoTarget.checked = true
      this.modifyAdditionalPaymentFormPayableYesTarget.checked = false
    }

    additionalPaymentModal.modal();
  }

  // rounds 2 decimal places numbers up for 5 and and down for 4
  // e.g 1.275 is 1.28 and 1.274 is 1.27
  //
  // @param amount [String] to which to convert to value with 2 decimal places
  //
  // @return [String] the rounded number
  roundNum(num, precision) {
    return +(Math.round(+(num + 'e' + precision)) + 'e' + -precision);
  }
}
